
import { ComponentCreator, createComponentCreator, styleInject } from 'packages/css-component-modules';

const _css = `.StyledIframeLoader_c624fe57851f1d38{display:flex;justify-content:center;align-items:center;margin:70px auto}`;
styleInject(_css)

/** StyledIframeLoader Props */
export type StyledIframeLoaderCCM = {
  /** StyledIframeLoader Component Custom Properties */
  // No custom properties found

  /** StyledIframeLoader Modifier Flags */
  // No modifiers classes found
};
/** Base StyledIframeLoader component */
export const StyledIframeLoader: ComponentCreator<StyledIframeLoaderCCM> = createComponentCreator({
  "name": "StyledIframeLoader",
  "base": "StyledIframeLoader_c624fe57851f1d38",
  "prop": {},
  "mod": {}
});

