import { useBiometricsConfig } from 'apps/acp/packages/acp-biometrics';
import { useState, useEffect } from 'react';
export const useBiometricsType = () => {
  const biometricsStoragePlugin = useBiometricsConfig();
  const [biometricType, setBiometricType] = useState(null);
  useEffect(() => {
    let disposed = false;

    (async () => {
      const type = await biometricsStoragePlugin.getType();

      if (!disposed) {
        setBiometricType(type);
      }
    })(); // We can tell react to call this function when our hook isn't active


    return () => {
      disposed = true;
    };
  }, [setBiometricType, biometricsStoragePlugin]);
  return biometricType;
};
export const useBiometricsHasValue = username => {
  const biometricsStoragePlugin = useBiometricsConfig();
  const [hasPassword, setHasPassword] = useState(null);
  useEffect(() => {
    let disposed = false;

    (async () => {
      const isPasswordSaved = await biometricsStoragePlugin.hasValue(username);

      if (!disposed) {
        setHasPassword(isPasswordSaved);
      }
    })(); // We can tell react to call this function when our hook isn't active


    return () => {
      disposed = true;
    };
  }, [setHasPassword, biometricsStoragePlugin, username]);
  return hasPassword;
};
export const useBiometricsGetValue = username => {
  const biometricsStoragePlugin = useBiometricsConfig();
  const [password, setPassword] = useState(null);
  const [error, setError] = useState(null);
  useEffect(() => {
    (async () => {
      if (username) {
        try {
          const savedPassword = await biometricsStoragePlugin.getValue(username);
          setPassword(savedPassword);
        } catch (e) {
          const error = new Error(e);
          setError(error);
        }
      }
    })(); // We can tell react to call this function when our hook isn't active


    return () => {
      setPassword(null);
      setError(null);
    };
  }, [setPassword, biometricsStoragePlugin, username, setError, password, error]);
  return [password, error];
};
/* eslint-disable @typescript-eslint/no-use-before-define */

export const useBiometricsSetValue = key => {
  const biometricsStoragePlugin = useBiometricsConfig();

  const setterFunction = value => {
    (async () => {
      try {
        await biometricsStoragePlugin.setValue(key, value);
        setReturnType([true, setterFunction, null]);
      } catch (exception) {
        const error = new Error(exception);
        setReturnType([false, setterFunction, error]);
      }
    })();
  };

  const [returnType, setReturnType] = useState([false, setterFunction, null]);
  return returnType;
};