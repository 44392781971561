import __NS_DYNAMIC_REACT_CONTENT___0 from "./acp-biometrics-introduction.strings/content.default.js";
import { Content, createScopedReactContentImporter } from "packages/react-content/runtime";
const NS_DYNAMIC_REACT_CONTENT_MANAGER = createScopedReactContentImporter(undefined, {
  "default": () => __NS_DYNAMIC_REACT_CONTENT___0
});
import { Body, Title, Disclosure } from 'legos/typography';
import { createElement, Fragment } from 'react';
import { EnrollmentContainer } from './styles/acp-biometrics-intoduction-success-failure.ccm.css';
import { ButtonRaised } from 'legos/button/raised';
import { ButtonFlat } from 'legos/button/flat';
import { ContentBlock } from 'legos/content-block';
import { InPageFlowLayout } from 'legos/in-page-flow-layout';
import { CenterChildren } from '../components';
import { SystemIcon } from 'legos/system-icon';
import { Illustration } from 'legos/illustration';
import { isAndroid, isIOS } from 'packages/platform-detector';
import { SendEventAnalytics } from 'apps/acp/packages/react-analytics';
import { BIOMETRICS_ANALYTICS_CONSTANTS } from '../acp-biometrics-ga-analytics';
import { useSendAnalytics } from 'packages/react-analytics';
import { useHistory } from 'packages/react-nano-router';
export const BiometricsIntroduction = ({
  title,
  biometricsType,
  brand,
  backLinks,
  nextGoLink
}) => {
  const sendAnalytics = useSendAnalytics();
  const history = useHistory();
  const moveBack = {
    /**
     * This will help in capture send analytics event
     * when clicked on InPageFlowLayout back button.
     */
    href: '#',
    onClick: e => {
      e.preventDefault();
      sendAnalytics({
        event: BIOMETRICS_ANALYTICS_CONSTANTS.ACTIONS.WARNING.DISMISS,
        payload: {
          category: BIOMETRICS_ANALYTICS_CONSTANTS.CATEGORY,
          label: BIOMETRICS_ANALYTICS_CONSTANTS.LABEL
        }
      });
      history.replaceState(null, '', backLinks.url());
    }
  };
  return createElement(Fragment, null, createElement(SendEventAnalytics, {
    analyticsEvent: {
      event: BIOMETRICS_ANALYTICS_CONSTANTS.ACTIONS.WARNING.VIEWED,
      payload: {
        category: BIOMETRICS_ANALYTICS_CONSTANTS.CATEGORY,
        label: BIOMETRICS_ANALYTICS_CONSTANTS.LABEL
      }
    }
  }), createElement(InPageFlowLayout, {
    title: title,
    backAnchor: moveBack
  }, createElement(ContentBlock, null, createElement(EnrollmentContainer.div, null, biometricsType === 'touch' && createElement(IntroIconFingerPrint, {
    type: biometricsType
  }), biometricsType === 'face' && createElement(IntroIconFace, null), createElement(Title, null, biometricsType === 'touch' && createElement(Content, {
    hash: "0c353c81729fe2b03aa01c1bac53bca6",
    devVariables: {
      brand: brand
    },
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  }), biometricsType === 'face' && createElement(Content, {
    hash: "da93da99a3ac07e3270f421e2207d461",
    devVariables: {
      brand: brand
    },
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(Body, null, biometricsType === 'touch' && createElement(Content, {
    hash: "984d237b00f0ff47d492380edf531134",
    devVariables: {
      brand: brand
    },
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  }), biometricsType === 'face' && createElement(Content, {
    hash: "11bf552db99b4eef7d8dccbb8ffb6060",
    devVariables: {
      brand: brand
    },
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(Body, null, createElement(Content, {
    hash: "8bf2378d8d4aaec8bd67876b81e2e2aa",
    devVariables: {},
    componentsAndExpressions: [() => biometricsType === 'touch' ? 'Fingerprint' : 'Face ID', () => biometricsType === 'touch' ? 'fingerprint access' : 'Face ID'],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), biometricsType === 'touch' && createElement(Disclosure, null, createElement(Content, {
    hash: "3e2dd4f4db28bbdd3408a87fba5d1316",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(CenterChildren, null, createElement(ButtonRaised, {
    color: "positive",
    onClick: () => {
      sendAnalytics({
        event: BIOMETRICS_ANALYTICS_CONSTANTS.ACTIONS.BIOMETRICS_INTRODUCTION.WARNING_GET_STARTED,
        payload: {
          category: BIOMETRICS_ANALYTICS_CONSTANTS.CATEGORY,
          label: BIOMETRICS_ANALYTICS_CONSTANTS.LABEL
        }
      });
      history.replaceState(null, '', nextGoLink.url());
    }
  }, createElement(Content, {
    hash: "ea72e3dbd9c8bcbcaca4f975818cd96d",
    devVariables: {},
    componentsAndExpressions: [() => biometricsType === 'touch' ? 'Get Started' : 'Close'],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), biometricsType === 'touch' && createElement(ButtonFlat, {
    color: "default",
    onClick: () => {
      sendAnalytics({
        event: BIOMETRICS_ANALYTICS_CONSTANTS.ACTIONS.BIOMETRICS_INTRODUCTION.WARNING_CANCEL,
        payload: {
          category: BIOMETRICS_ANALYTICS_CONSTANTS.CATEGORY,
          label: BIOMETRICS_ANALYTICS_CONSTANTS.LABEL
        }
      });
      history.replaceState(null, '', backLinks.url());
    }
  }, createElement(Content, {
    hash: "7bb7665996444fcb6f76c7ac48d2b7bb",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  }))), biometricsType === 'face' && createElement(Disclosure, null, createElement(Content, {
    hash: "7054b0bc427127cec220e72ce3f81b17",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  }))))));
};

const IntroIconFingerPrint = ({
  type
}) => {
  return createElement(Fragment, null, type === 'touch' && isAndroid() && createElement(SystemIcon, {
    type: "fingerprintAndroid",
    color: "primary",
    size: 9
  }), type === 'touch' && isIOS() && createElement(SystemIcon, {
    type: "fingerprint",
    color: "primary",
    size: 9
  }));
};

const IntroIconFace = () => createElement(Illustration, {
  type: "face-id",
  label: "image illustrating face id"
});