export default { 
  h0550ba18ac53529d431aab1fac844c58: [[({}, [C0]) => [C0("8. Federal Law."), " You acknowledge and agree that your consent to electronic Communications is being provided in connection with a transaction affecting interstate commerce that is subject to the federal Electronic Signatures in Global and National Commerce Act (\"E-SIGN Act\"), and that you and we both intend that the Act apply to the fullest extent possible to validate our ability to conduct business with you by electronic means."], {}]],
  h12d181614c20400a9cf29ad9950a72cf: [[({}, []) => ["We suggest you read this document carefully and print a copy for your reference."], {}]],
  h1de02f5da328b8c16b97a1e8a8369285: [[({}, [C0]) => [C0("5. Hardware and Software Requirements."), " In order to access, view, and retain electronic Communications that we make available to you, you must have:"], {}]],
  h21152bbb34105659c0dcd08037466fb9: [[({}, []) => ["an Internet browser that supports 128 bit encryption;"], {}]],
  h223a5d2f1cf86e1808b323ece19992f3: [[({}, []) => ["Privacy policies and notices"], {}]],
  h26a7e67c544db0d666b051d6754086bb: [[({}, []) => ["THIS DISCLOSURE, AND THE TERMS IT CONTAINS, SUPERSEDES ANY SIMILAR DISCLOSURE APPEARING IN THE PAPER VERSION OF THE CARDHOLDER AGREEMENT YOU RECEIVED WITH YOUR CARD."], {}]],
  h431d23ee18fb815987133e04fbb55a2e: [[({}, []) => ["a personal computer (for PCs: Pentium 120 Hhz or higher; for Macintosh, Power Mac 9500, Power PC 604 processor 120-MHz Base or higher), operating system and telecommunications connections to the Internet capable of receiving, accessing, displaying, and either printing or storing Communications received from us in electronic form via a plain text-formatted e-mail or by access to our web site using one of the browsers specified above; and"], {}]],
  h44a1c62fef99fb9a57027c266b40caa4: [[({}, [C0]) => [C0("9. Termination/Changes."), " We reserve the right, in our sole discretion, to discontinue the provision of your electronic Communications, or to terminate or change the terms and conditions on which we provide electronic Communications. We will provide you with notice of any such termination or change as required by law."], {}]],
  h523fd96306d067cc6a12193c325b92d7: [[({
  brand: brand
}, []) => ["or writing to us at ", brand.name, ", ", brand.contact_address_line1, ",", " ", brand.contact_address_line2, ". We may charge you a service charge for the delivery of paper copies of certain Communications provided to you electronically pursuant to this authorization. See the Fee Schedule in your Agreement for details about this service charge. We reserve the right, but assume no obligation, to provide a paper (instead of electronic) copy of any Communication that you have authorized us to provide electronically."], {}]],
  h588eeb0fb8deacddcf89dcc7fbd915bf: [[({
  cardType: cardType
}, []) => ["Netspend ", cardType, " \xAE Prepaid Card"], {}]],
  h600ec68b9cb6682a39ba94945e240846: [[({}, [C0]) => [C0("1. Scope of Communications to Be Provided in Electronic Form."), " ", "When you use a product or service to which this Disclosure applies, you agree that we may provide you with any Communications in electronic format, and that we may discontinue sending paper Communications to you, unless and until you withdraw your consent as described below. Your consent to receive electronic communications and transactions includes, but is not limited to:"], {}]],
  h67d57e61f8cba2932547c7d2d3cd7005: [[({}, [C0]) => [C0("Electronic Signatures in Global and National Commerce Act (\"E-SIGN\") Disclosure")], {}]],
  h7d15da0ef80ce88d7a7bba4311ce0bb6: [[({}, []) => ["Notices regarding insufficient funds or negative balances"], {}]],
  h7d48bda6dede7e834f33dc8e4e2ff157: [[({}, []) => ["an e-mail account with an Internet service provider and e-mail software in order to participate in our electronic Communications programs if you have elected to receive e-mail messages from us;"], {}]],
  h8061556e2ac4f1e770fafcbec95547d2: [[({
  brand: brand
}, [C0, C1]) => [C0("3. How to Withdraw Consent."), " You may withdraw your consent to receive Communications in electronic form at any time by contacting us at ", C1(brand.phone_formatted), " or visiting the Site. If you do withdraw your consent, we will send subsequent Communications to you in writing to the most current mailing address we have for you in our records. We will not impose any fee to process the withdrawal of your consent to receive electronic Communications. Any withdrawal of your consent to receive electronic Communications will be effective only after we have a reasonable period of time to process your request for withdrawal. In the meantime, you will continue to receive Communications in electronic form. If you withdraw your consent, the legal validity and enforceability of prior Communications delivered in electronic form will not be affected."], {}]],
  h8a623bd74b5617537ae2a8889eee05df: [[({
  brand: brand
}, [C0, C1]) => [C0("4. How to Update Your Records."), " It is your responsibility to provide us with a true, accurate and complete e-mail address if you have elected to receive e-mail messages from us, your contact information, and other information related to this Disclosure and your Card Account, and to maintain and update promptly any changes in this information. You can update information (such as your e-mail address) through the Site or by contacting us at", " ", C1(brand.phone_formatted, ".")], {}]],
  h8d4768cddc9b146dc9f468ff0ab9fe91: [[({}, []) => ["Adobe Acrobat Reader Version 8.0 or higher"], {}]],
  h8d769a8a1e484761264a6eb357e00f9e: [[({}, [C0]) => [C0("7. Communications in Writing."), " All Communications in either electronic or paper format from us to you will be considered \"in writing.\" You should print or download for your records a copy of this Disclosure and any other Communication that is important to you."], {}]],
  h8dbd9161ba798e6db495ddc08bba0369: [[({}, [C0]) => [C0("6. Requesting Paper Copies."), " We will not send you a paper copy of any Communication, unless you request it or we otherwise deem it appropriate to do so. You can obtain a paper copy of an electronic Communication by printing it yourself or by requesting that we mail you a paper copy, provided that such request is made within a reasonable time after we first provided the electronic Communication to you. To request a paper copy, contact us by calling", " "], {}]],
  h94386a9b483987dc134c07c478187066: [[({}, []) => ["All legal and regulatory disclosures and communications associated with your Card Account and any related products or services"], {}]],
  ha4eb2eaa23f59f8d860a5a0d701059c2: [[({}, []) => ["sufficient electronic storage capacity on your computer's hard drive or other data storage unit;"], {}]],
  ha896fe983745a2b4ef39cac220a0c2dc: [[({}, []) => ["Responses to claims filed in connection with your Card Account"], {}]],
  hb7bc905e165caeec01f4580e3639d903: [[({}, []) => ["Your Cardholder Agreement and any notices about a change in terms of your Cardholder Agreement"], {}]],
  hc8503df0e4a6c5e6623cc3c9ea93d7f7: [[({}, [C0, C1, C2, C3, C4, C5, C6, C7, C8]) => ["This E-SIGN Disclosure (", C0("\"Disclosure\""), ") applies to any and all communications or disclosures that we are legally required to provide to you in writing in connection with your", " ", C1(), " ", "and any related products and services (\"Communications\"). This Disclosure supplements and is to be construed in accordance with the terms contained in the Cardholder Agreement (", C2("\"Agreement\""), ") you received from the Issuing Bank. The words ", C3("\"we,\" \"us,\""), " ", "and ", C4("\"our\""), " refer to the Issuing Bank with whom you have your Card Account, and the words ", C5("\"you\""), " and", " ", C6("\"your\""), " mean you, the individual(s) identified on the Card Account. As used in this Disclosure, ", C7("\"Card Account\""), " means the", " ", C8(), " ", "account you have with us."], {}]],
  hd22120158a8d6e8d0da4adcac4784b2d: [[({
  brand: brand
}, [C0, C1]) => [C0("2. Method of Providing Communications to You in Electronic Form."), " ", "All Communications that we provide to you in electronic form will be provided either (1) via e-mail if you have elected to receive e-mail messages from us (2) by access to a web site that we will designate in an e-mail notice we send to you at the time the information is available, or (3) to the extent permissible by law, by access to the", " ", brand.website, " web site (the ", C1("\"Site\""), ")"], {}]],
  he471060dfc57c79bdcf1d6e9322da683: [[({
  cardType: cardType
}, []) => ["Netspend ", cardType, " \xAE Prepaid Card"], {}]] 
}