
import { ComponentCreator, createComponentCreator, styleInject } from 'packages/css-component-modules';

const _css = `.StyledCenterChildren_9f8f4dfdbf7ab061{display:flex;flex-direction:var(--flexDirection_8c672d153c8e91e0);justify-content:center;align-items:center}`;
styleInject(_css)

/** StyledCenterChildren Props */
export type StyledCenterChildrenCCM = {
  /** StyledCenterChildren Component Custom Properties */
  '$flexDirection': string;

  /** StyledCenterChildren Modifier Flags */
  // No modifiers classes found
};
/** Base StyledCenterChildren component */
export const StyledCenterChildren: ComponentCreator<StyledCenterChildrenCCM> = createComponentCreator({
  "name": "StyledCenterChildren",
  "base": "StyledCenterChildren_9f8f4dfdbf7ab061",
  "prop": {
    "$flexDirection": "--flexDirection_8c672d153c8e91e0"
  },
  "mod": {}
});

