export default { 
  h0c353c81729fe2b03aa01c1bac53bca6: [[({
  brand: brand
}, []) => ["Your ", brand.name, " Account is a Fingerprint Away"], {}]],
  h11bf552db99b4eef7d8dccbb8ffb6060: [[({
  brand: brand
}, []) => ["You now have the option of using Face ID\xAE to log in to the", " ", brand.name, " mobile app! Once you enable this option, login will be much easier and faster!"], {}]],
  h3e2dd4f4db28bbdd3408a87fba5d1316: [[({}, []) => ["REMEMBER: Anyone with a stored fingerprint on this device will have access to your Online Account."], {}]],
  h7054b0bc427127cec220e72ce3f81b17: [[({}, []) => ["Face ID is a registered trademark of Apple. Inc."], {}]],
  h7bb7665996444fcb6f76c7ac48d2b7bb: [[({}, []) => ["Cancel"], {}]],
  h8bf2378d8d4aaec8bd67876b81e2e2aa: [[({}, [C0, C1]) => ["Simply check the Enable", " ", C0(), " Login checkbox on the Login page and follow the instructions. If you haven\u2019t already, you will also need to enable", " ", C1(), " ", "in your device\u2019s settings."], {}]],
  h984d237b00f0ff47d492380edf531134: [[({
  brand: brand
}, []) => ["You are about to enable Fingerprint Login for quick access to the ", brand.name, " mobile app as an alternative to your username and password."], {}]],
  hda93da99a3ac07e3270f421e2207d461: [[({
  brand: brand
}, []) => ["Enable Facial Recognition Login for Your ", brand.name, " Account."], {}]],
  hea72e3dbd9c8bcbcaca4f975818cd96d: [[({}, [C0]) => [C0()], {}]] 
}