export const BIOMETRICS_ANALYTICS_CONSTANTS = {
  CATEGORY: 'ACP_Biometrics',
  LABEL: 'Biometrics',
  ACTIONS: {
    BIOMETRICS_INTRODUCTION: {
      WARNING_GET_STARTED: 'Warning_Get_Started',
      WARNING_CANCEL: 'Warning_Cancel'
    },
    BIOMETRICS_CHANGE: 'Biometrics_Change',
    BIOMETRICS_READ: {
      SUCCESS: 'Biometrics_Read_Success',
      FAIL: 'Biometrics_Read_Fail'
    },
    ENROLLMENT: {
      CONFIRMATION: 'Enrollment_Confirmation'
    },
    PASSWORD_LOCKOUT: 'Password_Lockout',
    SECURITY: {
      ON: 'Security_Settings_On',
      OFF: 'Security_Settings_Off',
      DISABLED: 'Disable_Confirm'
    },
    TERMS: {
      VIEWED: 'Terms_Viewed',
      DISMISS: 'Terms_Dismiss',
      TERMS_CONTINUE: 'Terms_Continue',
      TERMS_CANCEL: 'Terms_Cancel'
    },
    USERNAME_PASSWORD_CHANGE: 'Username_Password_Change',
    VERIFY_ACCOUNT: {
      VIEWED: 'Verify_Account_Viewed',
      DISMISS: 'Verify_Account_Dismiss',
      VERIFY_ACCOUNT_CONTINUE: 'Verify_Account_Continue',
      VERIFY_ACCOUNT_CANCEL: 'Verify_Account_Cancel'
    },
    WARNING: {
      VIEWED: 'Warning_Viewed',
      DISMISS: 'Warning_Dismiss'
    }
  }
};
export var GA_ACTIONS_EVENT;

(function (GA_ACTIONS_EVENT) {
  GA_ACTIONS_EVENT["CLICKED_REMEMBER_USERNAME"] = "clicked check box for remember user name";
  GA_ACTIONS_EVENT["ENABLE_BIOMETRICS_CHECKBOX"] = "Enable_Biometrics_Login_checkbox";
  GA_ACTIONS_EVENT["ENABLE_BIOMETRICS_INFO"] = "Enable_Biometrics_Login_info";
  GA_ACTIONS_EVENT["CLICKED_FORGOT_USERNAME"] = "clicked the forgot your username link";
  GA_ACTIONS_EVENT["CLICKED_FORGOT_PASSWORD"] = "clicked the forgot your password link";
  GA_ACTIONS_EVENT["CLICKED_LOGIN_BUTTON"] = "clicked login button";
  GA_ACTIONS_EVENT["FAILED_LOGIN_ATTEMPT"] = "failed login attempt";
  GA_ACTIONS_EVENT["USERNAME_PASSWORD_CHANGE"] = "Username_Password_Change";
  GA_ACTIONS_EVENT["BIOMETRICS_CHANGE"] = "Biometrics_Change";
  GA_ACTIONS_EVENT["CLICK_SEND_MY_CODE_BUTTON"] = "click send my code button";
  GA_ACTIONS_EVENT["CLICK_EDIT_CONTACT_INFO_LINK"] = "click edit contact info link";
  GA_ACTIONS_EVENT["CLICK_SKIP_FOR_NOW_LINK"] = "click skip for now link";
  GA_ACTIONS_EVENT["CLICK_REGISTER_DEVICE_CHECKBOX"] = "click register device checkbox";
  GA_ACTIONS_EVENT["CLICK_INFO_ICON"] = "click info icon";
  GA_ACTIONS_EVENT["CLICK_SUBMIT_BUTTON"] = "click submit button";
  GA_ACTIONS_EVENT["CLICK_HAVE_A_NEW_ONE_SENT_LINK"] = "click have a new one sent link";
  GA_ACTIONS_EVENT["LAND_ON_GET_CODE_SCREEN"] = "viewed get code";
  GA_ACTIONS_EVENT["LAND_ON_VERIFY_CODE_SCREEN"] = "viewed verify code";
})(GA_ACTIONS_EVENT || (GA_ACTIONS_EVENT = {}));