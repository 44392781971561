import __NS_DYNAMIC_REACT_CONTENT___0 from "./acp-biometrics-esign.strings/content.default.js";
import { Content, createScopedReactContentImporter } from "packages/react-content/runtime";
const NS_DYNAMIC_REACT_CONTENT_MANAGER = createScopedReactContentImporter(undefined, {
  "default": () => __NS_DYNAMIC_REACT_CONTENT___0
});
import { createElement, Fragment } from 'react';
import { InPageFlowLayout } from 'legos/in-page-flow-layout';
import { ContentBlock } from 'legos/content-block';
import { Body, Bold, LinkBody, Title } from 'legos/typography';
import { EsignLayout, MrgTop, CommunicationLayout, LiLayout } from './styles/acp-biometrics-esign.ccm.css';
import { useExternalLinkHandler } from 'packages/external-link-opener/react';
import { useAcpBiometricsTheme } from '../app.theme';
export const BiometricsEsign = ({
  title,
  cancelAnchor,
  children
}) => {
  return createElement(InPageFlowLayout, {
    title: title,
    cancelAnchor: cancelAnchor
  }, createElement(ContentBlock, null, createElement(Title, null, "E-Sign Consent"), createElement(CommunicationLayout.div, null, createElement(Body, null, "Communications will be sent to the following e-mail address we have on file for you. You can change your email address below."), children)));
};
export const EsignDisclosureContent = ({
  cardType,
  esignProductName,
  brand
}) => {
  const {
    lightDisclosureGreyColor,
    neutralBlackColor
  } = useAcpBiometricsTheme();
  return createElement(EsignLayout.div, {
    $borderColor: lightDisclosureGreyColor
  }, createElement(MrgTop.div, null, createElement(Body, null, createElement(Content, {
    hash: "67d57e61f8cba2932547c7d2d3cd7005",
    devVariables: {},
    componentsAndExpressions: [(...children) => createElement(Bold, null, children)],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  }))), createElement(Body, null, createElement(Content, {
    hash: "12d181614c20400a9cf29ad9950a72cf",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(Body, null, createElement(Content, {
    hash: "26a7e67c544db0d666b051d6754086bb",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(Body, null, createElement(Content, {
    hash: "c8503df0e4a6c5e6623cc3c9ea93d7f7",
    devVariables: {},
    componentsAndExpressions: [(...children) => createElement(Bold, null, children), () => esignProductName ? esignProductName : createElement(Content, {
      hash: "e471060dfc57c79bdcf1d6e9322da683",
      devVariables: {
        cardType: cardType
      },
      componentsAndExpressions: [],
      importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
    }), (...children) => createElement(Bold, null, children), (...children) => createElement(Bold, null, children), (...children) => createElement(Bold, null, children), (...children) => createElement(Bold, null, children), (...children) => createElement(Bold, null, children), (...children) => createElement(Bold, null, children), () => esignProductName ? esignProductName : createElement(Content, {
      hash: "588eeb0fb8deacddcf89dcc7fbd915bf",
      devVariables: {
        cardType: cardType
      },
      componentsAndExpressions: [],
      importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
    })],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(Body, null, createElement(Content, {
    hash: "600ec68b9cb6682a39ba94945e240846",
    devVariables: {},
    componentsAndExpressions: [(...children) => createElement(Bold, null, children)],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(Fragment, null, createElement("ul", null, createElement(LiLayout.li, {
    $textColor: neutralBlackColor
  }, createElement(Content, {
    hash: "94386a9b483987dc134c07c478187066",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(LiLayout.li, {
    $textColor: neutralBlackColor
  }, createElement(Content, {
    hash: "b7bc905e165caeec01f4580e3639d903",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(LiLayout.li, {
    $textColor: neutralBlackColor
  }, createElement(Content, {
    hash: "223a5d2f1cf86e1808b323ece19992f3",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(LiLayout.li, {
    $textColor: neutralBlackColor
  }, createElement(Content, {
    hash: "a896fe983745a2b4ef39cac220a0c2dc",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(LiLayout.li, {
    $textColor: neutralBlackColor
  }, createElement(Content, {
    hash: "7d15da0ef80ce88d7a7bba4311ce0bb6",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })))), createElement(Body, null, createElement(Content, {
    hash: "d22120158a8d6e8d0da4adcac4784b2d",
    devVariables: {
      brand: brand
    },
    componentsAndExpressions: [(...children) => createElement(Bold, null, children), (...children) => createElement(Bold, null, children)],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(Body, null, createElement(Content, {
    hash: "8061556e2ac4f1e770fafcbec95547d2",
    devVariables: {
      brand: brand
    },
    componentsAndExpressions: [(...children) => createElement(Bold, null, children), (...children) => createElement(Bold, null, children)],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(Body, null, createElement(Content, {
    hash: "8a623bd74b5617537ae2a8889eee05df",
    devVariables: {
      brand: brand
    },
    componentsAndExpressions: [(...children) => createElement(Bold, null, children), (...children) => createElement(Bold, null, children)],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(Body, null, createElement(Content, {
    hash: "1de02f5da328b8c16b97a1e8a8369285",
    devVariables: {},
    componentsAndExpressions: [(...children) => createElement(Bold, null, children)],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(Fragment, null, createElement("ul", null, createElement(LiLayout.li, {
    $textColor: neutralBlackColor
  }, createElement(Content, {
    hash: "21152bbb34105659c0dcd08037466fb9",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(LiLayout.li, {
    $textColor: neutralBlackColor
  }, createElement(Content, {
    hash: "a4eb2eaa23f59f8d860a5a0d701059c2",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(LiLayout.li, {
    $textColor: neutralBlackColor
  }, createElement(Content, {
    hash: "7d48bda6dede7e834f33dc8e4e2ff157",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(LiLayout.li, {
    $textColor: neutralBlackColor
  }, createElement(Content, {
    hash: "431d23ee18fb815987133e04fbb55a2e",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(LiLayout.li, {
    $textColor: neutralBlackColor
  }, createElement(Content, {
    hash: "8d4768cddc9b146dc9f468ff0ab9fe91",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })))), createElement(Body, null, createElement(Content, {
    hash: "8dbd9161ba798e6db495ddc08bba0369",
    devVariables: {},
    componentsAndExpressions: [(...children) => createElement(Bold, null, children)],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  }), createElement(Bold, null, createElement(LinkBody, useExternalLinkHandler(`tel:${brand.phone}`), brand.phone_formatted)), ' ', createElement(Content, {
    hash: "523fd96306d067cc6a12193c325b92d7",
    devVariables: {
      brand: brand
    },
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(Body, null, createElement(Content, {
    hash: "8d769a8a1e484761264a6eb357e00f9e",
    devVariables: {},
    componentsAndExpressions: [(...children) => createElement(Bold, null, children)],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(Body, null, createElement(Content, {
    hash: "0550ba18ac53529d431aab1fac844c58",
    devVariables: {},
    componentsAndExpressions: [(...children) => createElement(Bold, null, children)],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(Body, null, createElement(Content, {
    hash: "44a1c62fef99fb9a57027c266b40caa4",
    devVariables: {},
    componentsAndExpressions: [(...children) => createElement(Bold, null, children)],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })));
};