declare global {
  interface Window {
    plugins?: any;
  }
}

export type BiometricsStorageType = 'touch' | 'face' | 'none';

export interface BiometricStoragePlugin {
  /**
   * Get the biometrics type that is available
   */
  getType(): Promise<BiometricsStorageType>;
  /**
   * Check if bio-metrics has a value for the current key. Useful
   * to check before attempting to retrieve a value
   *
   * Returns `false` if `BiometricsType` is `none`
   */
  hasValue(key: string): Promise<boolean>;

  /**
   * Set a value in secure storage using a specific key.
   * Set value to `null` to delete the key from storage altogether.
   */
  setValue(key: string, value: string | null): Promise<void>;

  /**
   * This is the main method to get an item out of storage.
   * Call it with a key to get the value of the key.
   */
  getValue(key: string): Promise<string>;
}

interface CordovaBiometricsPlugin {
  /**
   * I will Check if touchid is available on the used device.
   * The successCallback gets the biometryType argument with
   * 'face' on iPhone X, 'touch' on other devices.
   */
  isAvailable: (
    successCallback: (biometricType: 'face' | 'touch') => void,
    errorCallback: (errorMessage: string) => void
  ) => void;

  /**
   * I will save a password under the key in the device keychain,
   * which can be retrieved using a fingerprint. userAuthenticationRequired
   * if true will save after authentication with fingerprint, if false there's
   * no need to authenticate to save. Default to true, if not set.
   */
  save: (
    key: string,
    password: string | null,
    userAuthenticationRequired: boolean,
    successCallback: () => void,
    errorCallback: (message: string) => void
  ) => void;

  /**
   * I will open the fingerprint dialog, for the given key, showing an additional
   * message. successCallback will return the password stored in key chain.
   * errorCallback will return the error code, where -1 indicated not available.
   */
  verify: (
    key: string,
    message: string,
    successCallback: (password: string) => void,
    errorCallback: (errorMessage: string) => void
  ) => void;

  /**
   * I will check if there is a password stored within the keychain for the given key
   */
  has: (
    key: string,
    successCallback: () => void,
    errorCallback: () => void
  ) => void;

  // I will delete the password stored under given key from the keychain
  delete: (
    key: string,
    successCallback: () => void,
    errorCallback: (message: string) => void
  ) => void;
}
export class BiometricsStorageCordovaPlugin implements BiometricStoragePlugin {
  constructor(private readonly touchIdPlugin: CordovaBiometricsPlugin) {}

  getType(): Promise<BiometricsStorageType> {
    return new Promise((resolve) => {
      this.touchIdPlugin.isAvailable(
        (type: BiometricsStorageType) => resolve(type),
        () => resolve('none')
      );
    });
  }

  hasValue(key: string): Promise<boolean> {
    if (key === '') {
      key = 'undefined';
    }
    return new Promise((resolve) => {
      this.touchIdPlugin.has(
        key,
        () => resolve(true),
        () => resolve(false)
      );
    });
  }

  setValue(key: string, value: string | null): Promise<void> {
    return new Promise((resolve, reject) => {
      if (value !== null) {
        this.touchIdPlugin.save(key, value, true, resolve, reject);
      } else if (value === null) {
        this.touchIdPlugin.delete(key, resolve, reject);
      }
    });
  }

  getValue(key: string): Promise<string> {
    return new Promise((resolve, reject) => {
      this.touchIdPlugin.verify(
        key,
        `Log in with username ${key}`,
        resolve,
        reject
      );
    });
  }
}

export class BiometricsStorageNoopPlugin implements BiometricStoragePlugin {
  getType(): Promise<BiometricsStorageType> {
    return Promise.resolve('none');
  }

  hasValue(): Promise<boolean> {
    return Promise.resolve(false);
  }

  setValue(): Promise<void> {
    return Promise.reject('biometrics.not_available');
  }

  getValue(): Promise<string> {
    return Promise.reject('biometrics.not_available');
  }
}
