
import { ComponentCreator, createComponentCreator, styleInject } from 'packages/css-component-modules';

const _css = `.EnrollmentContainer_effe526b6b192244{text-align:center}.HeadingContainer_6adf0cb1db126eac{margin:0 50px}.ChangeOrDisable_f1b114069bd893ef{margin:12px}`;
styleInject(_css)

/** EnrollmentContainer Props */
export type EnrollmentContainerCCM = {
  /** EnrollmentContainer Component Custom Properties */
  // No custom properties found

  /** EnrollmentContainer Modifier Flags */
  // No modifiers classes found
};
/** Base EnrollmentContainer component */
export const EnrollmentContainer: ComponentCreator<EnrollmentContainerCCM> = createComponentCreator({
  "name": "EnrollmentContainer",
  "base": "EnrollmentContainer_effe526b6b192244",
  "prop": {},
  "mod": {}
});


/** HeadingContainer Props */
export type HeadingContainerCCM = {
  /** HeadingContainer Component Custom Properties */
  // No custom properties found

  /** HeadingContainer Modifier Flags */
  // No modifiers classes found
};
/** Base HeadingContainer component */
export const HeadingContainer: ComponentCreator<HeadingContainerCCM> = createComponentCreator({
  "name": "HeadingContainer",
  "base": "HeadingContainer_6adf0cb1db126eac",
  "prop": {},
  "mod": {}
});


/** ChangeOrDisable Props */
export type ChangeOrDisableCCM = {
  /** ChangeOrDisable Component Custom Properties */
  // No custom properties found

  /** ChangeOrDisable Modifier Flags */
  // No modifiers classes found
};
/** Base ChangeOrDisable component */
export const ChangeOrDisable: ComponentCreator<ChangeOrDisableCCM> = createComponentCreator({
  "name": "ChangeOrDisable",
  "base": "ChangeOrDisable_f1b114069bd893ef",
  "prop": {},
  "mod": {}
});

