function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { createElement, Fragment, useState } from 'react';
import { CircularProgress } from 'legos/circular-progress';
import { StyledIframeLoader } from './styles/acp-iframe.ccm.css';
export const Iframe = props => {
  const [loading, setLoading] = useState(true);

  const frameOnload = () => {
    setLoading(false);
  };

  return createElement(Fragment, null, loading && createElement(IframeLoader, null), createElement("iframe", _extends({}, props, {
    onLoad: frameOnload
  })));
};

const IframeLoader = () => {
  return createElement(StyledIframeLoader.div, null, createElement(CircularProgress, {
    color: "default",
    size: "normal"
  }));
};