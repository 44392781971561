
import { ComponentCreator, createComponentCreator, styleInject } from 'packages/css-component-modules';

const _css = `.IntroductionConsentDisclosure_61aa13d7199ab9af{color:var(--textColor_c5f5efa15c765ed4)}.IframeLayout_84b4f73513727065{width:100%;height:200px;-webkit-overflow-scrolling:touch;overflow-y:scroll;border:1px solid;border-radius:2px;color:var(--borderColor_b36e1bed8ebd4103)}`;
styleInject(_css)

/** IntroductionConsentDisclosure Props */
export type IntroductionConsentDisclosureCCM = {
  /** IntroductionConsentDisclosure Component Custom Properties */
  '$textColor': string;

  /** IntroductionConsentDisclosure Modifier Flags */
  // No modifiers classes found
};
/** Base IntroductionConsentDisclosure component */
export const IntroductionConsentDisclosure: ComponentCreator<IntroductionConsentDisclosureCCM> = createComponentCreator({
  "name": "IntroductionConsentDisclosure",
  "base": "IntroductionConsentDisclosure_61aa13d7199ab9af",
  "prop": {
    "$textColor": "--textColor_c5f5efa15c765ed4"
  },
  "mod": {}
});


/** IframeLayout Props */
export type IframeLayoutCCM = {
  /** IframeLayout Component Custom Properties */
  '$borderColor': string;

  /** IframeLayout Modifier Flags */
  // No modifiers classes found
};
/** Base IframeLayout component */
export const IframeLayout: ComponentCreator<IframeLayoutCCM> = createComponentCreator({
  "name": "IframeLayout",
  "base": "IframeLayout_84b4f73513727065",
  "prop": {
    "$borderColor": "--borderColor_b36e1bed8ebd4103"
  },
  "mod": {}
});

