
import { ComponentCreator, createComponentCreator, styleInject } from 'packages/css-component-modules';

const _css = `.EsignLayout_ad1ccdf81d665122{-webkit-overflow-scrolling:touch;display:block;height:220px;overflow-y:scroll;padding:0 15px;margin:0 0 20px;border:1px solid;color:var(--borderColor_cd0ee01d34685994)}.CommunicationLayout_788ffab7ca5b18f8{margin-bottom:30px;margin-top:10px}.MrgTop_42e045cf28fdc189{margin-top:20px}.LiLayout_c89e29154d622a1b{font-size:16px;font-weight:300;line-height:20.8px;margin-bottom:16px;font-family:-apple-system,BlinkMacSystemFont,Roboto,Arial,Helvetica Neue,sans-serif;color:var(--textColor_5324ac280e64ebcc)}`;
styleInject(_css)

/** EsignLayout Props */
export type EsignLayoutCCM = {
  /** EsignLayout Component Custom Properties */
  '$borderColor': string;

  /** EsignLayout Modifier Flags */
  // No modifiers classes found
};
/** Base EsignLayout component */
export const EsignLayout: ComponentCreator<EsignLayoutCCM> = createComponentCreator({
  "name": "EsignLayout",
  "base": "EsignLayout_ad1ccdf81d665122",
  "prop": {
    "$borderColor": "--borderColor_cd0ee01d34685994"
  },
  "mod": {}
});


/** CommunicationLayout Props */
export type CommunicationLayoutCCM = {
  /** CommunicationLayout Component Custom Properties */
  // No custom properties found

  /** CommunicationLayout Modifier Flags */
  // No modifiers classes found
};
/** Base CommunicationLayout component */
export const CommunicationLayout: ComponentCreator<CommunicationLayoutCCM> = createComponentCreator({
  "name": "CommunicationLayout",
  "base": "CommunicationLayout_788ffab7ca5b18f8",
  "prop": {},
  "mod": {}
});


/** MrgTop Props */
export type MrgTopCCM = {
  /** MrgTop Component Custom Properties */
  // No custom properties found

  /** MrgTop Modifier Flags */
  // No modifiers classes found
};
/** Base MrgTop component */
export const MrgTop: ComponentCreator<MrgTopCCM> = createComponentCreator({
  "name": "MrgTop",
  "base": "MrgTop_42e045cf28fdc189",
  "prop": {},
  "mod": {}
});


/** LiLayout Props */
export type LiLayoutCCM = {
  /** LiLayout Component Custom Properties */
  '$textColor': string;

  /** LiLayout Modifier Flags */
  // No modifiers classes found
};
/** Base LiLayout component */
export const LiLayout: ComponentCreator<LiLayoutCCM> = createComponentCreator({
  "name": "LiLayout",
  "base": "LiLayout_c89e29154d622a1b",
  "prop": {
    "$textColor": "--textColor_5324ac280e64ebcc"
  },
  "mod": {}
});

