import __NS_DYNAMIC_REACT_CONTENT___0 from "./biometrics-security-path-content.strings/content.default.js";
import { Content, createScopedReactContentImporter } from "packages/react-content/runtime";
const NS_DYNAMIC_REACT_CONTENT_MANAGER = createScopedReactContentImporter(undefined, {
  "default": () => __NS_DYNAMIC_REACT_CONTENT___0
});
import { createElement, Fragment } from 'react';
export const BiometricsSecurityPathDashboard = ({
  dashboardMfeEnabled,
  loginPreferencesMfeEnabled
}) => {
  return dashboardMfeEnabled ? createElement(Fragment, null, loginPreferencesMfeEnabled ? createElement(Content, {
    hash: "4c31d3d32cdf504508fc46e27f1517cd",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  }) : createElement(Content, {
    hash: "772ec9da230d14b7b50a030567681910",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })) : createElement(Content, {
    hash: "9c7d6943a11df2ca213d85881c7d67a8",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  });
};